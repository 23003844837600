import React from 'react'
import PropTypes from 'prop-types'
import { Figure, Figcaption } from '../Caption'
import { Img } from './styles'

const ImageLink = props =>
  props.href ? <a href={props.href}>{props.children}</a> : <>{props.children}</>

const Image = props => {
  const { alt, border, caption, link, loading, src, style, width } = props

  return (
    <Figure>
      <ImageLink href={link}>
        <Img
          alt={alt}
          border={border}
          loading={loading}
          $hasCaption={Boolean(caption)}
          src={src}
          style={style}
          width={width}
        />
      </ImageLink>
      {caption && <Figcaption>{caption}</Figcaption>}
    </Figure>
  )
}

ImageLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string
}

Image.defaultProps = {
  loading: 'lazy',
  border: false
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  border: PropTypes.bool,
  caption: PropTypes.string,
  link: PropTypes.string,
  loading: PropTypes.oneOf(['lazy', 'eager']),
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  width: PropTypes.number
}

export default Image
