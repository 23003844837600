import styled from 'styled-components'

export const Figure = styled.figure({
  margin: 0,
  marginBottom: '20px'
})

export const Figcaption = styled.figcaption({
  margin: 0,
  fontSize: '85%',
  lineHeight: 'var(--line-height-small)'
})
