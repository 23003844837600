import React from 'react'
import PropTypes from 'prop-types'
import Image from '../components/Image'
import Base from '../components/Base'
import Meta from '../components/Meta'

const About = ({ location }) => (
  <Base>
    <Meta location={location} pageTitle='The "About" page' />
    <h1>About</h1>
    <Image
      alt="A black-and-white photo of a man using a computer"
      src="/images/bukowski-on-hist-home-terminal.jpg"
    />
  </Base>
)

About.propTypes = {
  location: PropTypes.object
}

export default About
